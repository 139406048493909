import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'Theme/stitches.config';

import { GetCart } from 'Commerce/Cart/Cart';
import CheckoutHeader from '../Checkout/Components/CheckoutHeader/CheckoutHeader';
import OrderSummary from '../Checkout/Components/OrderSummary/OrderSummary';
import OrderConfirmationInfo from './Components/OrderConfirmationInfo/OrderConfirmationInfo';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import OrderConfirmationPageModel from 'Models/Pages/OrderConfirmationPage/OrderConfirmationPageModel.interface';

function OrderConfirmationPage() {
  const { languageRoute } = useAppSettingsData();
  const { order } = useCurrentPage<OrderConfirmationPageModel>();
  const { cart } = GetCart(languageRoute);

  return (
    <>
      <CheckoutHeader showBackArrow={false}></CheckoutHeader>
      <ConfirmationContainer>
        <OrderConfirmation>
          <OrderConfirmationInfo order={order} />
        </OrderConfirmation>
        <CheckoutCart>
          {cart && <OrderSummary readOnly={true} order={order} />}
        </CheckoutCart>
      </ConfirmationContainer>
    </>
  );
}

export default OrderConfirmationPage;

const ConfirmationContainer = styled('main', {
  my: 8,
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: 32,
  gridTemplateAreas: '"confirmationinfo" "cart"',
  px: '$s100',
  maxW: '$screenMaxWidth',
  mx: 'auto',
  '@bpMin376': {
    px: '$s100',
  },
  '@bpMin721': {
    px: '$s100',
  },
  '@bpMin961': {
    gridTemplateAreas: '"confirmationinfo cart"',
    my: 16,
    gridTemplateColumns: '1fr 1fr',
  },
  '@bpMin1025': {
    px: '$s400',
  },
  '@bpMax720': {
    gridTemplateColumns: '1fr',
  },
});

const OrderConfirmation = styled('div', {
  gridArea: 'confirmationinfo',
});

const CheckoutCart = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 32,
  gridArea: 'cart',
  mx: 'auto',
});
